@import "toastMessages.scss";
@import "animations.scss";

html,
body {
  font-family: "Nunito", sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* general variables */
:root {
  // Colors
  --white: #fff;
  --black: #000;

  --gray-10: rgba(51, 51, 51, 1);
  --gray-9: rgba(51, 51, 51, 0.9);
  --gray-8: rgba(51, 51, 51, 0.8);
  --gray-7: rgba(51, 51, 51, 0.7);
  --gray-6: rgba(51, 51, 51, 0.6);
  --gray-5: rgba(51, 51, 51, 0.5);
  --gray-4: rgba(51, 51, 51, 0.4);
  --gray-3: rgba(51, 51, 51, 0.3);
  --gray-2: rgba(51, 51, 51, 0.2);
  --gray-1: rgba(51, 51, 51, 0.1);

  --neutral-pure: #999999;
  --neutral-light: #cacaca;
  --neutral-lightest: #eaeaea;
  --neutral-dark: #666666;
  --neutral-darkest: #333333;

  --primary-color: rgba(217, 32, 49, 1);
  --primary-color-9: rgba(217, 32, 49, 0.9);
  --primary-color-8: rgba(217, 32, 49, 0.8);
  --primary-color-7: rgba(217, 32, 49, 0.7);
  --primary-color-6: rgba(217, 32, 49, 0.6);
  --primary-color-5: rgba(217, 32, 49, 0.5);
  --primary-color-4: rgba(217, 32, 49, 0.4);
  --primary-color-3: rgba(217, 32, 49, 0.3);
  --primary-color-2: rgba(217, 32, 49, 0.2);
  --primary-color-1: rgba(217, 32, 49, 0.1);

  --primary-pure: #de2134;
  --primary-dark: #b11b2a;
  --primary-darkest: #85141f;
  --primary-light: #eb7a85;
  --primary-lightest: #f2a6ae;

  --secondary-color: rgba(35, 159, 221, 1);
  --secondary-color-9: rgba(35, 159, 221, 0.9);
  --secondary-color-8: rgba(35, 159, 221, 0.8);
  --secondary-color-7: rgba(35, 159, 221, 0.7);
  --secondary-color-6: rgba(35, 159, 221, 0.6);
  --secondary-color-5: rgba(35, 159, 221, 0.5);
  --secondary-color-4: rgba(35, 159, 221, 0.4);
  --secondary-color-3: rgba(35, 159, 221, 0.3);
  --secondary-color-2: rgba(35, 159, 221, 0.2);
  --secondary-color-1: rgba(35, 159, 221, 0.1);

  --secondary-pure: #0da6f2;
  --secondary-dark: #0a85c2;
  --secondary-darkest: #086391;
  --secondary-light: #6ec9f7;
  --secondary-lightest: #9edbfa;

  --background-default: #ffffff;
  --backgroud-light-red: #fcedef;
  --background-alternate: #f3fbfe;

  --error-color: #dd3545;
  --warning-color: #f1c40f;
  --success-color: #5dd360;

  --font-color: var(--black);
  --link-color: #007bff;

  // Sizes
  --size-xxxs: 4px;
  --size-xxs: 8px;
  --size-xs: 16px;
  --size-sm: 24px;
  --size-md: 32px;
  --size-lg: 48px;
  --size-xl: 60px;
  --size-xxl: 80px;
  --size-xxxl: 96px;

  // Paddings
  --padding-xxxs: 4px;
  --padding-xxs: 8px;
  --padding-xs: 12px;
  --padding-sm: 16px;
  --padding-md: 24px;
  --padding-lg: 32px;
  --padding-xl: 48px;
  --padding-xxl: 64px;
  --padding-xxxl: 96px;

  --field-height-sm: 35px;
  --field-height: 45px;
  --field-height-lg: 50px;

  // Border Radius
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 16px;
  --border-radius-xlg: 32px;
  --border-radius-circular: 50%;

  --box-border-radius: var(--border-radius-sm);
  --round-box-border-radius: var(--border-radius-xlg);
  --field-border-radius: var(--border-radius-sm);
  --round-field-border-radius: var(--border-radius-xlg);

  // box shadows
  --box-shadow-sm: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  --box-shadow-md: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
  --box-shadow-lg: 0 16px 32px 0 rgba(0, 0, 0, 0.16);
  --box-shadow-xl: 0 16px 48px 0 rgba(0, 0, 0, 0.24);
}

$primary-color: rgba(217, 32, 49, 1);

@media (max-width: 1080px) {
  html {
    font-size: 93.75%; // 15px
  }
}

@media (max-width: 728px) {
  html {
    font-size: 87.5%; // 14px
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.3rem;
}

h6 {
  font-size: 1.2rem;
}

p {
  font-size: 1rem;
}

label {
  margin: 0.5rem 0.75rem;
  font-size: 0.9rem;
}

/* Input */
textarea,
input:not([type="checkbox"]),
select {
  width: 100%;
  height: var(--field-height);
  background: var(--white);
  border: 1px solid var(--gray-2);
  border-radius: var(--field-border-radius);
  outline: none;
  padding: 0 1rem;
  font-size: 1rem;
}

::placeholder {
  color: var(--gray-5);
}

select {
  appearance: none;
}

.inputError {
  border: 1px solid var(--error-color) !important;
}

.inputErrorMsg {
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0.75rem;
  color: var(--error-color);
}
