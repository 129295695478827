/* Toast Messages */
.Toastify__toast-container {
  width: 40%;
  max-width: 450px;
  color: var(--gray-3);
  cursor: auto;
}

.Toastify__toast {
  border-radius: var(--box-border-radius);
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  cursor: auto;
}

.Toastify__toast-body {
  flex: 4;
  cursor: auto;
  color: inherit;
  padding-right: 1rem;

  a {
    text-decoration: underline !important;
    color: inherit;
  }
}

.Toastify__toast--dark {
  background: var(--gray-10) !important;
  color: var(--white) !important;
  .Toastify__toast-body {
    border-right: 1px solid var(--white);
  }
}

.Toastify__toast--default {
  background: var(--white) !important;
  color: var(--font-color) !important;
  .Toastify__toast-body {
    border-right: 1px solid var(--font-color);
  }
}

.Toastify__toast--error {
  background: var(--error-color);
  color: var(--white);
  .Toastify__toast-body {
    border-right: 1px solid var(--white);
  }
}

.Toastify__toast--info {
  background: var(--secondary-color);
  color: var(--white);
  .Toastify__toast-body {
    border-right: 1px solid var(--white);
  }
}

.Toastify__toast--success {
  background: var(--success-color);
  color: var(--white);
  .Toastify__toast-body {
    border-right: 1px solid var(--white);
  }
}

.Toastify__toast--warning {
  background: var(--warning-color);
  color: var(--font-color) !important;
  .Toastify__toast-body {
    border-right: 1px solid var(--font-color);
  }
}

@media (max-width: 768px) {
  .Toastify__toast-container {
    width: 90%;
  }

  .Toastify__toast-container--bottom-center {
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
