.toastBtn {
  flex: 1;
  max-width: 75px;
  height: 100%;
  color: inherit;
  background: none;
  border-radius: 0;
  padding: 0 0.5rem 0 1rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }
}
